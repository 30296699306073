<template>
    <div class="SearchInputWrapper">
        <input
            type="search"
            :placeholder="placeholder"
            :value="inputValue"
            class="SearchInput"
            @input="(event) => onChangeInput(event)"
        />
        <button
            v-if="inputValue"
            class="SearchInputWrapper__clear"
            @click="() => clearValue()"
        ></button>
    </div>
</template>

<script>
export default {
    props: {
        placeholder: String,
    },

    data() {
      return {
          inputValue: ""
      }
    },

    methods: {
        onChangeInput(event) {
            this.inputValue = event.target.value;
            this.$emit('getSearchValue', event.target.value);
        },

        clearValue() {
            this.inputValue = "";
            this.$emit('getSearchValue', "");
        }
    },
};
</script>

<style lang="scss" scoped>
@import "./SearchInput.scss";
</style>
