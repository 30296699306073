<template>
    <div class="card">
        <img class="card__icon" v-if="cardIcon" v-bind:src="cardIcon" />
        <p class="h4 card__title">
            {{ cardTitle }}
        </p>
        <p class="text2 colorFiord card__text">
            {{ cardText }}
        </p>
        <router-link v-if="cardRoute" v-bind:to="cardRoute" class="btn-text card__button">Подробнее</router-link>
    </div>
</template>

<script>
export default {
    name: "Card",

    props: {
        cardTitle: String,
        cardText: String,
        cardIcon: String,
        cardRoute: String,
    },
};
</script>

<style lang="scss">
@import "./card.scss";
</style>
