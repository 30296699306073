<template>
    <Layout>
        <div class="pageSection">
            <SideBar />

            <div class="pageContent">
                <div class="knowledgeBase">
                    <p class="pageTitle">База знаний</p>

                    <div class="knowledgeBase__header">
                        <div class="knowledgeBase__headerSearch">
                            <p class="h3 knowledgeBase__headerSearch_title">
                                Найдите ответ быстро
                            </p>
                            <p
                                class="text2 colorFiord knowledgeBase__headerSearch_subtitle"
                            >
                                Введите интересующий Вас вопрос
                            </p>
                            <div class="knowledgeBase__headerSearch_search">
                                <router-link
                                    :to="
                                        '/' +
                                            this.$route.params.slug +
                                            '/knowledge-base/search'
                                    "
                                >
                                    <SearchInput
                                        placeholder="Начните писать вопрос и выберите подходящий из списка ниже"
                                    />
                                </router-link>
                            </div>
                            <div class="knowledgeBase__headerSearch_results">
                                <p
                                    class="h5 colorGray knowledgeBase__headerSearch_results_title"
                                >
                                    Частые темы:
                                </p>
                                <div
                                    :to="
                                        '/' +
                                            this.$route.params.slug +
                                            '/knowledge-base/search'
                                    "
                                    class="knowledgeBase__headerSearch_results_list"
                                >
                                    <router-link
                                        :to="
                                            '/' +
                                                this.$route.params.slug +
                                                '/knowledge-base/search'
                                        "
                                        class="knowledgeBase__headerSearch_results_list_item"
                                        >обучение</router-link
                                    >
                                    <router-link
                                        :to="
                                            '/' +
                                                this.$route.params.slug +
                                                '/knowledge-base/search'
                                        "
                                        class="knowledgeBase__headerSearch_results_list_item"
                                        >оплата</router-link
                                    >
                                    <router-link
                                        :to="
                                            '/' +
                                                this.$route.params.slug +
                                                '/knowledge-base/search'
                                        "
                                        class="knowledgeBase__headerSearch_results_list_item"
                                        >воспроизведение видео</router-link
                                    >
                                    <router-link
                                        :to="
                                            '/' +
                                                this.$route.params.slug +
                                                '/knowledge-base/search'
                                        "
                                        class="knowledgeBase__headerSearch_results_list_item"
                                        >достижения</router-link
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="knowledgeBase__headerSupport">
                            <img
                                class="knowledgeBase__headerSupport_img"
                                alt="replySmile"
                                src="@/assets/img/pages/knowledgeBase/knowledgeBaseSearch/replySmile.png"
                            />
                            <p class="h4 knowledgeBase__headerSupport_title">
                                Не нашли ответ?
                            </p>
                            <p class="text2 colorFiord knowledgeBase__headerSupport_text">
                                Напишите нам - мы с радостью поможем Вам
                            </p>
                            <div class="knowledgeBase__headerSupportButton">
                                <Button extraClass="button--white" title="НАПИСАТЬ" />
                            </div>
                        </div>
                    </div>

                    <div class="knowledgeBase__content">
                        <div
                            class="knowledgeBase__contentItem"
                            v-for="item in cardItems"
                            :key="item.cardTitle"
                        >
                            <Card
                                :cardIcon="item.cardIcon"
                                :cardTitle="item.cardTitle"
                                :cardText="item.cardText"
                                :cardRoute="item.cardRoute"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import SideBar from "@/views/partials/sideBar/sideBar";
import SearchInput from "@/views/components/SearchInput/SearchInput";
import Button from "@/views/components/Button/Button";
import Card from "@/views/components/Card/Card";
import PageHeader from "@/views/partials/pageHeader/pageHeader";

import CardIcon from "../../../assets/img/pages/knowledgeBase/knowledgeBaseSection/rocketIcon.png";
import NotebookIcon from "../../../assets/img/pages/knowledgeBase/knowledgeBaseSection/notebookIcon.png";
import CupIcon from "../../../assets/img/pages/knowledgeBase/knowledgeBaseSection/cupIcon.png";
import SheduleIcon from "../../../assets/img/pages/knowledgeBase/knowledgeBaseSection/sheduleIcon.png";
import FolderIcon from "../../../assets/img/pages/knowledgeBase/knowledgeBaseSection/folderIcon.png";
import BooksIcon from "../../../assets/img/pages/knowledgeBase/knowledgeBaseSection/booksIcon.png";

export default {
    name: "knowledgeBase",

    components: {
        Layout,
        SideBar,
        Button,
        SearchInput,
        Card,
        PageHeader,
    },

    data() {
        return {
            title: "База знаний",

            cardItems: [
                {
                    cardIcon: CardIcon,
                    cardTitle: "С чего начать обучение?",
                    cardText:
                        "В этой статье рассказывается с чего лучше начать обучение, как подготовиться и что нужно знать о начале обучения. Так же вы найдете ответы на все вопросы по старту.",
                    cardRoute: `/${this.$route.params.slug}/knowledge-base/inner`,
                },
                {
                    cardIcon: NotebookIcon,
                    cardTitle: "С чего начать обучение?",
                    cardText:
                        "В этой статье рассказывается с чего лучше начать обучение.",
                    cardRoute: `/${this.$route.params.slug}/knowledge-base/inner`,
                },
                {
                    cardIcon: CupIcon,
                    cardTitle: "С чего начать обучение?",
                    cardText:
                        "В этой статье рассказывается с чего лучше начать обучение, как подготовиться и что нужно знать о начале обучения. Так же вы найдете ответы на все вопросы по старту.",
                    cardRoute: `/${this.$route.params.slug}/knowledge-base/inner`,
                },
                {
                    cardIcon: SheduleIcon,
                    cardTitle: "С чего начать обучение?",
                    cardText:
                        "В этой статье рассказывается с чего лучше начать обучение, как подготовиться и что нужно знать о начале обучения. Так же вы найдете ответы на все вопросы по старту.",
                    cardRoute: `/${this.$route.params.slug}/knowledge-base/inner`,
                },
                {
                    cardIcon: FolderIcon,
                    cardTitle: "С чего начать обучение?",
                    cardText:
                        "В этой статье рассказывается с чего лучше начать обучение, как подготовиться и что нужно знать о начале обучения. Так же вы найдете ответы на все вопросы по старту. В этой статье рассказывается с чего лучше начать обучение, как подготовиться и что нужно знать о начале обучения. Так же вы найдете ответы на все вопросы по старту.",
                    cardRoute: `/${this.$route.params.slug}/knowledge-base/inner`,
                },
                {
                    cardIcon: BooksIcon,
                    cardTitle: "С чего начать обучение?",
                    cardText:
                        "В этой статье рассказывается с чего лучше начать обучение, как подготовиться и что нужно знать о начале обучения. ",
                    cardRoute: `/${this.$route.params.slug}/knowledge-base/inner`,
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
@import "knowledgeBase.scss";
</style>
