var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"pageSection"},[_c('SideBar'),_c('div',{staticClass:"pageContent"},[_c('div',{staticClass:"knowledgeBase"},[_c('p',{staticClass:"pageTitle"},[_vm._v("База знаний")]),_c('div',{staticClass:"knowledgeBase__header"},[_c('div',{staticClass:"knowledgeBase__headerSearch"},[_c('p',{staticClass:"h3 knowledgeBase__headerSearch_title"},[_vm._v(" Найдите ответ быстро ")]),_c('p',{staticClass:"text2 colorFiord knowledgeBase__headerSearch_subtitle"},[_vm._v(" Введите интересующий Вас вопрос ")]),_c('div',{staticClass:"knowledgeBase__headerSearch_search"},[_c('router-link',{attrs:{"to":'/' +
                                        this.$route.params.slug +
                                        '/knowledge-base/search'}},[_c('SearchInput',{attrs:{"placeholder":"Начните писать вопрос и выберите подходящий из списка ниже"}})],1)],1),_c('div',{staticClass:"knowledgeBase__headerSearch_results"},[_c('p',{staticClass:"h5 colorGray knowledgeBase__headerSearch_results_title"},[_vm._v(" Частые темы: ")]),_c('div',{staticClass:"knowledgeBase__headerSearch_results_list",attrs:{"to":'/' +
                                        this.$route.params.slug +
                                        '/knowledge-base/search'}},[_c('router-link',{staticClass:"knowledgeBase__headerSearch_results_list_item",attrs:{"to":'/' +
                                            this.$route.params.slug +
                                            '/knowledge-base/search'}},[_vm._v("обучение")]),_c('router-link',{staticClass:"knowledgeBase__headerSearch_results_list_item",attrs:{"to":'/' +
                                            this.$route.params.slug +
                                            '/knowledge-base/search'}},[_vm._v("оплата")]),_c('router-link',{staticClass:"knowledgeBase__headerSearch_results_list_item",attrs:{"to":'/' +
                                            this.$route.params.slug +
                                            '/knowledge-base/search'}},[_vm._v("воспроизведение видео")]),_c('router-link',{staticClass:"knowledgeBase__headerSearch_results_list_item",attrs:{"to":'/' +
                                            this.$route.params.slug +
                                            '/knowledge-base/search'}},[_vm._v("достижения")])],1)])]),_c('div',{staticClass:"knowledgeBase__headerSupport"},[_c('img',{staticClass:"knowledgeBase__headerSupport_img",attrs:{"alt":"replySmile","src":require("@/assets/img/pages/knowledgeBase/knowledgeBaseSearch/replySmile.png")}}),_c('p',{staticClass:"h4 knowledgeBase__headerSupport_title"},[_vm._v(" Не нашли ответ? ")]),_c('p',{staticClass:"text2 colorFiord knowledgeBase__headerSupport_text"},[_vm._v(" Напишите нам - мы с радостью поможем Вам ")]),_c('div',{staticClass:"knowledgeBase__headerSupportButton"},[_c('Button',{attrs:{"extraClass":"button--white","title":"НАПИСАТЬ"}})],1)])]),_c('div',{staticClass:"knowledgeBase__content"},_vm._l((_vm.cardItems),function(item){return _c('div',{key:item.cardTitle,staticClass:"knowledgeBase__contentItem"},[_c('Card',{attrs:{"cardIcon":item.cardIcon,"cardTitle":item.cardTitle,"cardText":item.cardText,"cardRoute":item.cardRoute}})],1)}),0)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }